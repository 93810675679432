(function($) {
    var tl = gsap.timeline({repeat: -1, repeatDelay: 1});
    tl.to(".globes", {left: "15.625%", top: "55.1%", scale: 0.6, duration: 1.5});
    tl.to(".globe1", {rotate: "15deg", duration: 1}, "-=0.5");
    tl.to(".globe2, .globe2-light", {rotate: "0deg", duration: 1}, "-=1");
    tl.to(".globe1", {opacity: 0, duration: 0.5}, "-=0.5");
    tl.to(".globe2", {opacity: 1, duration: 0.5}, "-=1");
    tl.to(".hand", {left: "49.6%", bottom: "0%", scale: 1, duration: 1}, "-=1.25");
    tl.to(".globe2-light", {opacity: 1, duration: 0.5}, "-=0.5");
    tl.to(".globe2-light", {opacity: 0, duration: 0.5}, "+=0.5");
    tl.to(".hand", {opacity: 0, scale: 1.5, duration: 0.5}, "-=0.5");
    tl.to(".globes", {left: "28%", top: "88.15%", scale: 1, rotate: "-45deg", duration: 1.5}, "-=0.5");
    tl.to(".globes", {opacity: 0, duration: 0.5}, "-=0.5");
    tl.to(".big-globe", {opacity: 1, top: "0%", duration: 1}, "-=0.5");
    tl.from(".big-globe-main", {opacity: 0, top: "10%", duration: 0.5}, "-=0.5");
    tl.to(".big-globe-light1", {opacity: 1, duration: 0.5});
    tl.to(".big-globe-light2", {opacity: 1, duration: 0.5}, "-=0.2");
    tl.to(".big-globe", {opacity: 0, top: "50%", duration: 2}, "+=2");

    var tl2 = gsap.timeline({repeat: -1, repeatDelay: 5});
    tl2.from(".index-footprint-img .map", {opacity: 0, y: 30, duration: 0.5}, "+=0.5");
    tl2.from(".index-footprint-img .pin1", {opacity: 0, y: 10, duration: 1});
    tl2.from(".index-footprint-img .pin2", {opacity: 0, y: 10, duration: 1}, "-=0.4");

    function setScrollPos(){
        if ($('.index-banner').height() < $(window).height()) {
            var scrollTopPos = $('.index-banner').height();
        } else {
            var scrollTopPos = $(window).height();
        }
        $('.index-banner .bt-scroll').css("top", scrollTopPos);
    }
    var doit;
    $(window).on('resize', function(event) {
        clearTimeout(doit);
        doit = setTimeout(setScrollPos, 100);
    }).resize();
    $('.index-banner-wrap').on('click', '.bt-scroll', function(e) {
        var t = $(this).attr('href');
        $('html,body').animate({
            scrollTop: $(t).offset().top
        }, 400);
        return false;
    });
})($);